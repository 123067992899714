import { PROVIDER_TYPE_LOCAL_STORAGE_KEY } from 'pages/OidcLogin/constants';

export const storeItem = (key: string, value: string) => localStorage.setItem(key, value);

export const getItem = (key: string) => {
  return localStorage.getItem(key);
};

export const removeItem = (key: string) => {
  return localStorage.removeItem(key);
};

export const clearStorage = () => {
  const backendUrl = getItem('backendUrl');
  const providerType = getItem(PROVIDER_TYPE_LOCAL_STORAGE_KEY);
  localStorage.clear();
  if (backendUrl) storeItem('backendUrl', backendUrl);
  if (providerType) storeItem(PROVIDER_TYPE_LOCAL_STORAGE_KEY, providerType);
};
export const clearState = () => localStorage.removeItem('persist:root');
