import { SocialLoginRequest } from 'shared-types';
import axios from 'axios';
import { isAxiosError } from 'utils/error';
import { getItem, storeItem } from './storage.service';
import Configuration from '../configuration/configuration';

export interface GetTokensResponse {
  access_token: string;
  usuario: { id_credor_externo: string; id_escritorio: number; email: string };
}

const getTokens = async ({
  code,
  scope,
  redirectUri,
}: {
  code: string;
  scope: string;
  redirectUri: string;
}): Promise<GetTokensResponse> => {
  const { data } = await axios.get<GetTokensResponse>(`${Configuration.backendUrl}/login/tokens`, {
    withCredentials: true,
    params: {
      code,
      scope,
      redirect_uri: redirectUri,
    },
  });

  return data;
};

export const getSsoTokens = async (
  jwt: string,
  clientId: string,
  additionalParams: string | undefined,
): Promise<GetTokensResponse> => {
  const { data } = await axios.post<GetTokensResponse>(
    `${Configuration.backendUrl}/login/sso`,
    {
      office_token: clientId,
      external_jwt: jwt,
    },
    {
      params: {
        additional_data: additionalParams,
      },
    },
  );

  return data;
};

type SocialLoginResponse = GetTokensResponse | { httpStatus: number; email?: string };
export const isLoginError = (
  r: SocialLoginResponse,
): r is { httpStatus: number; email?: string } => {
  return !!(r as any).email || !!(r as any).httpStatus;
};

export const socialLogin = async (req: SocialLoginRequest): Promise<SocialLoginResponse> => {
  try {
    const { data } = await axios.post<GetTokensResponse>(
      `${Configuration.backendUrl}/login/social`,
      req,
    );
    return data;
  } catch (err) {
    if (!isAxiosError(err)) throw err;
    return { httpStatus: Number(err?.code), email: err.response?.data.email };
  }
};

export const getTokenFromDid = async (did: string): Promise<GetTokensResponse> => {
  const { data } = await axios.get<GetTokensResponse>(`${Configuration.backendUrl}/login/tokens`, {
    withCredentials: true,
    params: {
      did,
    },
  });

  return data;
};

export const getAccessToken = (): string => {
  return getItem('access_token') || '';
};

export const setAccessToken = (token: string) => {
  storeItem('access_token', token);
};

export const refreshToken = async () => {
  const { data } = await axios.post(`${Configuration.backendUrl}/login/oidc/refresh-token`, null, {
    withCredentials: true,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
  });
  setAccessToken(data.access_token);
};

export default getTokens;
