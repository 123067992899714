import { CurrentUserResponse, UserPreferences } from 'shared-types';

export type SyncerMessages =
  | {
      type: 'setup';
      accessToken: string;
      backendUrl: string;
    }
  | {
      type: 'shutdown';
    }
  | { type: 'user_config_changed'; newConfig: UserPreferences };

export type SyncerOutboundMessages = {
  type: 'user_info_fetched';
  userInfo: CurrentUserResponse;
};

export const dispatchWorkerMessage = (w: Worker) => (msg: SyncerMessages) => w.postMessage(msg);

export const subscribeToWorkerEvent = (
  w: Worker,
): ((
  evt: SyncerOutboundMessages['type'],
  fn: (msg: SyncerOutboundMessages) => unknown,
) => void) => {
  const sub = (
    evt: SyncerOutboundMessages['type'],
    fn: (msg: SyncerOutboundMessages) => unknown,
  ) => {
    return w.addEventListener(evt, msg => {
      fn(msg.data);
    });
  };

  return sub;
};
