import Configuration from 'configuration/configuration';
import { getAccessToken } from 'service/auth.service';
import Syncer from './configsyncer?worker';
import { dispatchWorkerMessage, subscribeToWorkerEvent } from './types';

let w: Worker | undefined;
/**
 * Creates a singleton instance of the ConfigSyncer worker
 * This worker pulls user permissions from time to time and updates changed user configs (like hide values or collapse menu)
 * @returns
 */
export const ConfigSyncer = () => {
  if (!w) {
    const accessToken = getAccessToken();

    if (accessToken) {
      w = new Syncer();
      w.postMessage({ type: 'setup', accessToken, backendUrl: Configuration.backendUrl });
    } else {
      return {
        worker: undefined,
        dispatchWorkerMessage: undefined,
        subscribeToWorkerEvent: undefined,
        destroyWorker: undefined,
      };
    }
  }

  return {
    worker: w,
    dispatchWorkerMessage: dispatchWorkerMessage(w),
    subscribeToWorkerMessage: subscribeToWorkerEvent(w),
    destroyWorker: () => {
      w?.postMessage({ type: 'shutdown' });
      w?.terminate();
      w = undefined;
    },
  };
};
